












































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import markdownIt from "markdown-it";

@Component
export default class QuestionComponent extends Vue {
  @Prop()
  public onayami?: any;
  @Prop()
  public fileUrls?: any[];
  @Prop()
  public isPart?: any;
  @Prop()
  public isOperator?: boolean;
  @Prop()
  public clinic?: any;
  @Prop()
  public onayamiDetail?: boolean;

  md = new markdownIt({ html: false, breaks: true });
  liffUrl = "";

  compiledMarkdownText() {
    return this.onayami.detail ? this.md.render(this.onayami.detail) : "";
  }

  updateLesson() {
    // qusetionはアテンドオンラインではつかっていない
    if (this.onayami.type == "question") {
      this.$router.push(`/qscreate/${this.$route.params.id}`);
    } else if (this.onayami.type == "class") {
      this.$router.push(`/onayami/create/${this.$route.params.id}`);
    }
  }
}
